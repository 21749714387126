import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class ListGroup extends Vue {
    @Prop() items: any;

    private goToContact() {
        this.$router.push({
            name: 'kontakt',
            params:{
                // @ts-ignore
                isContact: true,
            }
        }).then(()=>{}).catch(()=>{});
    }
}

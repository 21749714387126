import Vue from "vue";

export function initFilters() {

  Vue.filter('formatNumber', (value:any) => {
    if(value){
      return new Intl.NumberFormat('de-DE').format(value);
    }else
      return '';
  });
}

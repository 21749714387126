import AppBar from "@/core/appbar/appbar.vue";
import CookieDialog from "@/core/cookie/cookie-dialog.vue";
import {defineComponent} from "vue";

export default defineComponent({
    components: {
        AppBar,
        CookieDialog
    },
    metaInfo() {
        return {
            meta: [{
                name: 'canonical',
                content: 'https://immobilienrente-plus.de' + this.$route.path,
            }]
        }
    },
})
import {defineComponent} from "vue";
import slider from '@/shared/slider/slider.vue'
import Router from "@/router";
import banner from "@/shared/banner/banner.vue";
import {Analytics} from "@/shared/analytics/analytics";

export default defineComponent({
    methods: {
        Router() {
            return Router
        }
    },
    components : {banner, slider},
    data() {
        return {
            items: [{
                desktop : require('@/assets/img/banners/banner_8.webp'),
                mobile : require('@/assets/img/banners/banner_8.webp')
            }]
        }
    },
    mounted(){
        const analyticsService = new Analytics();
        analyticsService.trackPageView('/datenschutz', 'Datenschutz')
    }
})
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMenu,{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"menu_text_color no-background-hover font-15",attrs:{"text":"","ripple":false}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VIcon,{attrs:{"right":""}},[_vm._v(" icon-arrow_drop_down")])],1)]}}])},[_c(VList,_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[_c(VListItem,{attrs:{"to":item.link,"link":""}},[_c(VListItemTitle,{staticClass:"menu_text_color"},[_vm._v(_vm._s(item.name))])],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
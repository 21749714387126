import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mx-auto w-100"},[_c(VList,[_vm._l((_vm.items),function(item,key){return (item.data.length === 0)?_c(VListItem,{key:key,attrs:{"sub-group":""}},[_c(VListItemContent,[_c(VListItemTitle,{on:{"click":_vm.goToContact}},[_vm._v(_vm._s(item.title))])],1)],1):_vm._e()}),_vm._l((_vm.items),function(item,key){return (item.data.length > 0)?_c(VListGroup,{key:key,attrs:{"sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)]},proxy:true}],null,true)},_vm._l((item.data),function(item,key){return _c(VListItem,{key:key,attrs:{"link":""}},[_c(VListItemContent,[_c('RouterLink',{attrs:{"to":item.link,"title":item.name}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.name)}})],1)],1)],1)}),1):_vm._e()})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
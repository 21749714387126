import axios from "axios";
import {IContact} from "@/shared/model/contact.model";
const baseUrl = '/api/sendcontact';

export default class ContactService {
    public sendContact(contactData: IContact): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post(baseUrl, contactData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
}

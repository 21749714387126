import DropDown from "@/shared/drop_down/drop_down.vue";
import listGroup from '@/shared/list_group/list_group.vue'
import Vue from 'vue'
import Component from "vue-class-component";
import {Watch} from "vue-property-decorator";


@Component({
    components: {
        DropDown,
        listGroup
    }
})
export default class AppbarComponent extends Vue {
    private isCalculateScreen = false;
    private extended = false;
    private items = [
        {
            title: 'Modelle', link: '', data: [
                {name: 'Einmalzahlung & Wohnungsrecht', link: '/einmalzahlung-wohnungsrecht'},
                {name: 'Leib-/Zeitrente & Wohnungsrecht', link: '/zeitrente'},
                {name: 'Einmalzahlung & Nießbrauchrecht', link: '/niessbrauchrecht'},
                {name: 'Einmalzahlung & Rückmiete', link: '/rueckmiete'},
                {name: 'Sicher ins neue Zuhause', link: '/sicher-ins-neue-zuhause'},
                {name: 'Teilverkauf & Nutzungsentgelt', link: '/teilverkauf-2'}
            ],
        },
        {
            title: 'Unternehmen', link: '', data: [
                {name: "Über Immobilienrente Plus", link: '/unternehmen'},
                {name: "Verrentungs-Check", link: '/rechner'},
                {name: "Kundenstimmen", link: '/kundenstimmen'}
            ],
        },
        {
            title: 'Kontakt',
            link: '/Kontakt',
            data: []
        }
    ];
    private ModelleItems = [
        {name: 'Einmalzahlung & Wohnungsrecht', link: '/einmalzahlung-wohnungsrecht'},
        {name: 'Leib-/Zeitrente & Wohnungsrecht', link: '/zeitrente'},
        {name: 'Einmalzahlung & Nießbrauchrecht', link: '/niessbrauchrecht'},
        {name: 'Einmalzahlung & Rückmiete', link: '/rueckmiete'},
        {name: 'Sicher ins neue Zuhause', link: '/sicher-ins-neue-zuhause'},
        {name: 'Teilverkauf & Nutzungsentgelt', link: '/teilverkauf-2'}
    ];
    private UnternehmenItem = [
        {name: "Über Immobilienrente Plus", link: '/unternehmen'},
        {name: "Verrentungs-Check", link: '/rechner'},
        {name: "Kundenstimmen", link: '/kundenstimmen'}
    ];

    @Watch('$route.path')
    handelRoute(newValue: string) {
        this.extended = false;
        this.isCalculateScreen = newValue === '/rechner';
    }

    mounted() {
        if (this.$route.path === '/rechner') {
            this.isCalculateScreen = true;
        }
    }

    private open() {
        this.extended = !this.extended;
    }

    private goToContact() {
        this.$router.push({
            name: 'kontakt',
            params:{
                // @ts-ignore
                isContact: true,
            }
        }).then(()=>{}).catch(()=>{});
    }
}



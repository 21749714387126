import axios from 'axios';
import {IPersonalInfo} from "@/shared/model/personal-info.model";
import {IZipcodeData, ZipcodeData} from "@/shared/model/zipcode-data.model";

const baseApiUrl = 'api/sendperson';
const baseZipcode = 'api/zipcode';

export default class CalculatorService {
    public sendPersonalInfo(personalInfo: IPersonalInfo): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post(baseApiUrl, personalInfo)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public checkZipCodeValid(zipcode: any): Promise<IZipcodeData> {
        return new Promise<IZipcodeData>((resolve, reject) => {
            axios
                .post(baseZipcode, {"zipcode": zipcode})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

}

import Vue, {defineAsyncComponent} from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '',
        component: defineAsyncComponent(() => import('@/core/home/home.vue')),
        name: 'Home',
        meta: {
            breadcrumb: [
                {name: 'home', link: '/'},
            ]
        }
    },
    {
        path: '/infopaket',
        component: defineAsyncComponent(() => import( '@/core/contact/contact.vue')),
        name: 'Infopaket',
    },
    {
        path: '/kontakt',
        component: defineAsyncComponent(() => import( '@/layouts/layout.vue')),
        name: 'kontakt',
        meta: {
            breadcrumb: [
                {name: 'home', link: '/'},
                {name: 'kontakt', link: '/kontakt'},
            ]
        },
        children: [
            {
                path: '',
                name: 'kontakt',
                component: defineAsyncComponent(() => import( '@/core/contact/contact.vue')),
                beforeEnter: (to, from, next) => {
                    if (!from.name && !to.params.isContact) {
                        next({
                            name: 'kontakt',
                            params: {
                                // @ts-ignore
                                isContact: true,
                            }
                        });
                    } else {
                        next();
                    }
                }
            },
            {
                path: 'anfrage-gesendet',
                component: defineAsyncComponent(() => import( '@/core/contact/requestSent/requestSent.vue'))
            },
            {
                path: '/Kundenstimmen',
                component: defineAsyncComponent(() => import( '@/core/customerDetail/customerDetail.vue')),
                name: 'Kundenstimmen',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'Kundenstimmen', link: '/Kundenstimmen'},
                    ]
                },
            },
            {
                path: '/einmalzahlung-wohnungsrecht',
                component: defineAsyncComponent(() => import( '@/core/retirement/retirement.vue')),
                name: 'einmalzahlung wohnungsrecht',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'einmalzahlung wohnungsrecht', link: '/einmalzahlung-wohnungsrecht'},
                    ]
                },
            },
            {
                path: '/zeitrente',
                component: defineAsyncComponent(() => import( '@/core/retirement/retirement.vue')),
                name: 'zeitrente',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'zeitrente', link: '/zeitrente'},
                    ]
                },
            },
            {
                path: '/niessbrauchrecht',
                component: defineAsyncComponent(() => import( '@/core/retirement/retirement.vue')),
                name: 'niessbrauchrecht',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'niessbrauchrecht', link: '/niessbrauchrecht'},
                    ]
                },
            },
            {
                path: '/rueckmiete',
                component: defineAsyncComponent(() => import( '@/core/retirement/retirement.vue')),
                name: 'rueckmiete',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'rueckmiete', link: '/rueckmiete'},
                    ]
                },
            },
            {
                path: '/sicher-ins-neue-zuhause',
                component: defineAsyncComponent(() => import( '@/core/retirement/retirement.vue')),
                name: 'sicher ins neue zuhause',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'sicher ins neue zuhause', link: '/sicher-ins-neue-zuhause'},
                    ]
                },
            },
            {
                path: '/teilverkauf-2',
                component: defineAsyncComponent(() => import( '@/core/retirement/retirement.vue')),
                name: 'teilverkauf 2',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'teilverkauf 2', link: '/teilverkauf-2'},
                    ]
                },
            },
            {
                path: '/unternehmen',
                component: defineAsyncComponent(() => import( '@/core/company/company.vue')),
                name: 'unternehmen',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'unternehmen', link: '/unternehmen'},
                    ]
                },
            },
            {
                path: '/fullDetail:*',
                component:  defineAsyncComponent(() => import( '@/core/fullCustomerDetail/fullCustomerDetail.vue')),
                name: 'full customer detail',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'full customer detail', link: '/fullDetail:*'},
                    ]
                },
            },
            {
                path: '/impressum',
                component: defineAsyncComponent(() => import( '@/core/imprint/imprint.vue')),
                name: 'impressum',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'impressum', link: '/impressum'},
                    ]
                },
            },
            {
                path: '/datenschutz',
                component: defineAsyncComponent(() => import( "@/core/dataProtection/dataProtection.vue")),
                name: 'datenschutz',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'datenschutz', link: '/datenschutz'},
                    ]
                },
            }
        ]
    },
    {
        path: '/rechner',
        component: defineAsyncComponent(() => import( "@/layouts/layout-without-footer/layout-without-footer.vue")),
        children: [
            {
                path: '',
                component: defineAsyncComponent(() => import( '@/core/calculator/calculator.vue')),
                name: 'rechner',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'rechner', link: '/rechner'},
                    ]
                },
            },
            {
                path: 'danke',
                component: defineAsyncComponent(() => import( '@/core/thanks/thanks.vue')),
                name: 'danke',
                meta: {
                    breadcrumb: [
                        {name: 'home', link: '/'},
                        {name: 'rechner', link: '/rechner'},
                        {name: 'danke', link: '/rechner/danke'},
                    ]
                },
            }
        ]
    },
    {
        path: '*',
        redirect: '404_Not_Found',
    },
    {
        path: '/404_Not_Found',
        component: defineAsyncComponent(() => import( '@/core/notFoundPage/notFoundPage.vue'))
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: () => ({x: 0, y: 0}),
})

export default router

import Router from "@/router";


export default {
    methods: {
        Router() {
            return Router
        }
    },
    props : ["title" ,"items" ] ,
    data(){
        return {

        }
    }
}
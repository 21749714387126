import {defineComponent} from "vue";

export default defineComponent({
    props: ["items", "isAnimate"],

    mounted() {
        if (this.items.length > 0 ) {
            this.currentImage = this.items[0];
            this.showSlider = true;
        }
        if (this.isAnimate) {
            setTimeout(() => {
                this.showWithAnimation = this.isAnimate;
                setInterval(() => {
                    if (this.counter < this.items.length) {
                        this.currentImage = this.items[this.counter];
                        this.counter++;
                    } else {
                        this.counter = 0;
                        this.currentImage = this.items[this.counter];
                        this.counter++;
                    }
                }, 10000);
            }, 10000)
        }
    },
    data() {
        return {
            showSlider: false,
            showWithAnimation: false,
            currentImage: {
                desktop: null,
                mobile: null,
            },
            counter: 1,
        };
    },

});

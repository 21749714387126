import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import '@/assets/css/main.scss'
import '@/assets/icomoon/style.css'
import * as dayjs from 'dayjs'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import VueCookies from 'vue-cookies'
import CalculatorService from '@/core/calculator/calculator.service';
import ContactService from "@/core/contact/contact.service";
import * as filters from '@/shared/date/filters';
import * as GmapVue from "gmap-vue";
import VueMeta from 'vue-meta'


Vue.config.productionTip = false
dayjs.extend(isLeapYear)
Vue.use(VueCookies, {expires: '90d'});
Vue.use(VueMeta)
Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyAXD4fLOgubSpcSQWob824JJ-BIuzp_v88',
        libraries: 'places',
    },

    installComponents: true,
});
filters.initFilters();

new Vue({
    router,
    vuetify,
    provide: {
        calculatorService: () => new CalculatorService(),
        contactService: () => new ContactService(),
    },
    render: h => h(App)
}).$mount('#app')

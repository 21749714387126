import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';
import DataProtection from "@/core/dataProtection/dataProtection.vue";
import {DSGVO_COOKIE_NAME} from "@/constants";
import {Analytics} from "@/shared/analytics/analytics";

@Component({
    components: {DataProtection}
})
export default class CookieConsent extends Vue {
    public showCookie = false;
    private showDialog = false;
    private analyticsService = new Analytics();

    public mounted(): void {
        const cookieValue = this.$cookies.get(DSGVO_COOKIE_NAME);

        if (cookieValue === 'accepted') {
            this.analyticsService.injectScriptInHead();
        } else if (!cookieValue) {
            this.showCookie = true;
        }
    }

    public setCookieValue(value: string): void {
        this.$cookies.set(DSGVO_COOKIE_NAME, value);
        this.showCookie = false;
        if (value === "accepted") {
            this.analyticsService.injectScriptInHead();
        }
    }

    private openPrivacyNotices() {
        this.showDialog = true;
    }
}

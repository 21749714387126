import axios from "axios";

export class Analytics {
    trackPageView(path: string, title: string) {
        const immoCookie = document.cookie.split(',');
        let cookieValue = '';
        immoCookie.map(item => {
            if (item.includes('immo-dsgvo')) {
                cookieValue = item.split('=')[1];
            }
        })
        if (cookieValue.includes('accepted')) {
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            // @ts-ignore
            window.dataLayer.push({
                'event': 'Pageview',
                'pagePath': '' + path,
                'pageTitle': title,
            });

            let pageViewUrl = 'https://www.google-analytics.com/collect?';
            pageViewUrl += '&tid=GTM-TRFWG2P';
            pageViewUrl += '&t=pageview';
            pageViewUrl += '&dh=https://immorente-plus.de';
            pageViewUrl += '&dp=' + path;
            pageViewUrl += '&dt=' + title;

            axios.get(pageViewUrl).then(res => console.log(res)).catch(err => console.error(err));
        }

    }

    injectScriptInHead() {
        const head: any = document.getElementById('head');
        const scriptTag = document.createElement('script');
        scriptTag.text = `(function (w, d, s, l, i) {
            const immoCookie = document.cookie.split(',');
            let cookieValue = '';
            immoCookie.map(item => {
                if (item.includes('immo-dsgvo')) {
                    cookieValue = item.split('=')[1];
                }
            })
            if (cookieValue.includes('accepted')) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            }
        })(window, document, 'script', 'dataLayer', 'GTM-TRFWG2P');`;


        head.appendChild(scriptTag);
    }
}